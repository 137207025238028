<template>
    <div class="p-6 bg-white rounded-xl shadow-lg w-4/5 mb-4">
        <h2 class="text-left text-md text-gray-600"><b>Estado de monitoreo actual</b></h2>
        <div class="flex flex-row">
            <div class="w-1/2 text-left">
                <p class="text-md text-gray-600"><b class="text-md text-blue-600">{{`${sample.progress}/${sample.total} criterios`}}</b> han sido evaluados</p>
                <a-progress :percent="sample.percent" :strokeWidth="15" strokeColor="green" strokeLinecap="square"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['sample']
}
</script>

<style>

</style>